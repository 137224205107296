import { createRoot } from "react-dom/client";
import {
    createBrowserRouter,
    RouterProvider,
  } from "react-router-dom";

import App from "./App";
  
  const router = createBrowserRouter([
    {
      path: "/",
      element: <App />,
    },
  ]);
  

const rootElement = document.getElementById("root")!;
const root = createRoot(rootElement); // createRoot(container!) if you use TypeScript
root.render(<RouterProvider router={router} />);
